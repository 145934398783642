import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import Confetti from "react-confetti";

const ScorePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const { score, totalQuestion } = location.state;
  const width = windowSize.width;
  const height = windowSize.height;
  const effectiviti = Math.ceil((score / totalQuestion) * 100);
  const [confetti, setConfetti] = useState(true);

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  }, []);

  return (
    <>
      {confetti && <Confetti width={width} height={height} />}
      <div>
        <div className="container-fluid min-vh-100 bg-dark text-secondary px-4 py-5 text-center">
          <div className="py-5">
            <h1 className="display-5 fw-bold text-white text-uppercase">
              CONGRATULATIONS
            </h1>
          </div>
          <div className="py-5">
            <h1 className="display-5  text-white ">
              Your score is <span className="fw-bold">{score} </span> <br />
              correct answer of{" "}
              <span className="fw-bold">{totalQuestion} </span>
            </h1>
          </div>
          <div className="py-5">
            <h4 className="display-5  text-white ">
              <span className="fw-bold text-danger">{effectiviti}% </span>{" "}
              Effective
            </h4>
          </div>
          <div className="d-grid gap-2 col-6 mx-auto">
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg"
              onClick={handleClick}
            >
              HOME
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScorePage;
