import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import CategoriesList from "./components/CategoriesList";
import TagsList from "./components/TagsList";
import LevelList from "./components/LevelList";
import Range from "./components/Range";
import ButtonTrivia from "./components/ButtonTrivia";
import Alerta from "./components/Alerta";
import "./style.css";

function App() {
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState("");
  const [level, setLevel] = useState("");
  const [questions, setQuestions] = useState("5");
  const [alertas, setAlertas] = useState("");
  const [toast, setToast] = useState(true);

  const handleCategory = (categorySelected) => setCategory(categorySelected);
  const handleTag = (tagSelected) => setTag(tagSelected);
  const handleLevel = (levelSelected) => setLevel(levelSelected);
  const handleQuanty = (rangeSelected) => setQuestions(rangeSelected);
  const handleAlerta = (alerta) => setAlertas(alerta);

  useEffect(() => {
    setTimeout(() => {
      setAlertas("");
    }, 4000);
  }, [alertas]);

  useEffect(() => {
    setTimeout(() => {
      setToast(false);
    }, 4000);
  }, []);

  return (
    <>
      {toast && (
        <div>
          <div className="alertFade bg-toast toast-container position-fixed bottom-0 end-0 p-3">
            <div
              id="liveToast"
              className=""
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-info-circle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#9e9e9e"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="12" y1="8" x2="12.01" y2="8" />
                  <polyline points="11 12 12 12 12 16 13 16" />
                </svg>
                <strong className="px-2 me-auto text-white text-uppercase">
                  {" "}
                  Information
                </strong>
              </div>
              <div className="toast-body text-white text-toast-trivia">
                It is not necessary to select all the options, <br />
                just click on start and you can begin.
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid min-vh-100 bg-dark text-secondary px-4 py-5 text-center mainHeader">
        <div className="py-5">
          <h1 className="display-5 fw-bold text-white text-uppercase">
            select your preferences
          </h1>
          <div className="col-lg-6 mx-auto">
            <Header />
            {alertas && <Alerta msg={alertas} />}
            <h2 className="text-white">Categories</h2>
            <CategoriesList handleCategory={handleCategory} />
            <h2 className="text-white">Sub-Categories</h2>
            <TagsList handleTag={handleTag} />
            <h2 className="text-white">Levels</h2>
            <LevelList handleLevel={handleLevel} />
            <h2 className="text-white">Quanty questions</h2>
            <Range handleQuanty={handleQuanty} />
          </div>
          <ButtonTrivia
            category={category}
            tag={tag}
            level={level}
            questions={questions}
            alerta={handleAlerta}
          />
        </div>
      </div>
    </>
  );
}

export default App;
