import React from "react";
import "../style.css";

const DateContact = ({ handleMenu, menu }) => {
  var sidenav = "sidenav";
  if (menu) {
    sidenav = "";
    sidenav += "sidenavOpen";
  } else {
    sidenav = "";
    sidenav += "sidenav";
  }
  return (
    <>
      <div className={sidenav}>
        <button onClick={handleMenu} className="closeButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-corner-up-right-double hoverSvg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke="#9e9e9e"
            fill="none"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 18v-6a3 3 0 0 1 3 -3h7" />
            <path d="M10 13l4 -4l-4 -4m5 8l4 -4l-4 -4" />
          </svg>
        </button>
        <div
          className="d-flex flex-column align-items-center
  justify-content-center h-100"
        >
          <div className="my-5 dataContact">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-code hoverSvg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#9e9e9e"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="7 8 3 12 7 16" />
              <polyline points="17 8 21 12 17 16" />
              <line x1="14" y1="4" x2="10" y2="20" />
            </svg>
            <span> Amaldo Molinares</span>
          </div>
          <div className="my-5 dataContact">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-brand-whatsapp hoverSvg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              strokeWidth="1.5"
              stroke="#9e9e9e"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
              <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
            </svg>
            <span className="fs-4 fw-bold"> +56 9 8200 9757</span>
          </div>
          <div className="my-5 dataContact">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-at hoverSvg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#9e9e9e"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="4" />
              <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
            </svg>
            <span> amaldo_molinares@hotmail.com</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateContact;
