import { createSlice } from "@reduxjs/toolkit";
import { triviaApi } from "../../../triviaApi/triviaApi";

const initialState = {
  categoriesList: [],
  categorySelected: "",
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoriesList: (state, action) => {
      state.categoriesList = action.payload;
    },
    setCategorySelected: (state, action) => {
      state.categorySelected = action.payload;
    },
  },
});

export const fetchCategoriesList = () => async (dispatch) => {
  try {
    const categoriesListData = await triviaApi.get("/categories");
    const resp = await categoriesListData.data;
    dispatch(setCategoriesList(resp));
  } catch (error) {
    console.log(error);
  }
};
export const { setCategoriesList, setCategorySelected } =
  categoriesSlice.actions;

export const categoriesListState = (state) => state.categories.categoriesList;
export const categorySelectedState = (state) =>
  state.categories.categorySelected;

export default categoriesSlice.reducer;
