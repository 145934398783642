import React from "react";
import Questions from "../components/Questions";

const QuestionsPage = () => {
  return (
    <>
      <div className="container-fluid min-vh-100 d-flex bg-dark px-4 py-5 text-center justify-content-center align-items-center">
        <Questions />
      </div>
    </>
  );
};

export default QuestionsPage;
