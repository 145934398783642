import React, { useState, useEffect } from "react";
import Select from "./Select";

const LevelList = ({ handleLevel }) => {
  const [level, setLevel] = useState("");

  const list = ["easy", "medium", "hard"];

  useEffect(() => handleLevel(level), [level]);

  return (
    <div className="mt-1 mb-3 p-1">
      <Select
        title="Level"
        dataComp={list}
        handleChange={(e) => {
          setLevel(e.target.value);
        }}
      />
    </div>
  );
};

export default LevelList;
