import { configureStore } from "@reduxjs/toolkit";

//reducers
import categoriesReducer from "./slices/categories/categoriesSlices";
import tagsReducer from "./slices/tags/tagsSlices";
import questionsRedcuer from "./slices/questions/questionsSlices";

export default configureStore({
  reducer: {
    categories: categoriesReducer,
    tags: tagsReducer,
    questions: questionsRedcuer,
  },
});
