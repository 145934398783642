import React from "react";
import ReactDOM from "react-dom/client";
//store
import { Provider } from "react-redux";
import store from "./store/store.js";
//stilos
import "bootstrap/dist/css/bootstrap.css";
//react router
import { BrowserRouter, Route, Routes } from "react-router-dom";

//pages
import QuestionsPage from "./pages/QuestionsPage";
import ScorePage from "./pages/ScorePage";
import HomePage from "./pages/HomePage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/questions" element={<QuestionsPage />} />
          <Route path="/score" element={<ScorePage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
