import React, { useState, useEffect } from "react";
import {
  fetchCategoriesList,
  categoriesListState,
} from "../store/slices/categories/categoriesSlices";
import { useDispatch, useSelector } from "react-redux";
import Select from "./Select";

export default function CategoriesList({ handleCategory }) {
  const [category, setCategory] = useState("");

  const list = useSelector(categoriesListState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesList());
  }, [dispatch]);

  useEffect(() => handleCategory(category), [category]);

  return (
    <div className="mt-1 mb-3 p-1">
      <Select
        title="Categories"
        dataComp={list}
        handleChange={(e) => {
          setCategory(e.target.value);
        }}
      />
    </div>
  );
}
