import { createSlice } from "@reduxjs/toolkit";
import { triviaApi } from "../../../triviaApi/triviaApi";

const initialState = {
  tagsList: [],
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTagsList: (state, action) => {
      state.tagsList = action.payload;
    },
  },
});

export const fetchTagsList = () => async (dispatch) => {
  try {
    const tagsListData = await triviaApi.get("/tags");
    const resp = await tagsListData.data;
    dispatch(setTagsList(resp));
  } catch (error) {
    console.log(error);
  }
};
export const { setTagsList } = tagsSlice.actions;

export const tagsListState = (state) => state.tags.tagsList;

export default tagsSlice.reducer;
