import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionsList: [],
};

export const questionsSlices = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestionsList: (state, action) => {
      state.questionsList = action.payload;
    },
  },
});

export const { setQuestionsList } = questionsSlices.actions;

export const questionsListState = (state) => state.questions.questionsList;

export default questionsSlices.reducer;
