import React, { useState } from "react";
import DateContact from "./DateContact";

const Header = () => {
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            ANOTHER TRIVIA GAME
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => handleMenu()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <DateContact handleMenu={handleMenu} menu={menu} />
        </div>
      </nav>
    </>
  );
};

export default Header;
