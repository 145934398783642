import React, { useState, useEffect } from "react";
import { fetchTagsList, tagsListState } from "../store/slices/tags/tagsSlices";
import { useDispatch, useSelector } from "react-redux";
import Select from "./Select";

export default function TagsList({ handleTag }) {
  const [tag, setTag] = useState("");

  const list = useSelector(tagsListState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTagsList());
  }, [dispatch]);

  useEffect(() => handleTag(tag), [tag]);

  return (
    <div className="mt-1 mb-3 p-1">
      <Select
        title="Sub-Categories"
        dataComp={list}
        handleChange={(e) => {
          setTag(e.target.value);
        }}
      />
    </div>
  );
}
