import React from "react";
import "../style.css";

const Alerta = ({ msg }) => {
  return (
    <div>
      <div className="alert alert-danger fade-in-down alertFade" role="alert">
        {msg}
      </div>
    </div>
  );
};

export default Alerta;
