import React from "react";

const Select = ({ title, dataComp, handleChange }) => {
  let id = `select-${title}`;

  let options;
  if (Array.isArray(dataComp)) {
    options = (
      <>
        {dataComp.map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </>
    );
  } else {
    options = (
      <>
        {Object.keys(dataComp).map((i) => (
          <option key={i} value={i}>
            {i}
          </option>
        ))}
      </>
    );
  }
  return (
    <select
      className="form-select"
      aria-label="Multiple select"
      name={id}
      id={id}
      onChange={handleChange}
    >
      <option value="">{title}</option>
      {options}
    </select>
  );
};

export default Select;
