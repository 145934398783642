import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { questionsListState } from "../store/slices/questions/questionsSlices";
import Alerta from "./Alerta";

const Questions = () => {
  const [score, setScore] = useState(0);
  const [questionsIndex, setQuestionsIndex] = useState(0);
  const [groupQuestion, setgroupQuestion] = useState([]);
  const [finish, setFinish] = useState(false);
  const [answerSelected, setAnswerSelected] = useState("");
  const [alertas, setAlertas] = useState("");

  const list = useSelector(questionsListState);

  const navigate = useNavigate();

  const totalQuestion = Object.keys(list).length;

  const respuestaCorrecta = () => {
    if (!answerSelected) {
      return setAlertas("Check one");
    }
    if (answerSelected === list[questionsIndex].correctAnswer) {
      setScore(score + 1);
      finishQuestion();
    } else {
      return finishQuestion();
    }
  };

  const finishQuestion = () => {
    setQuestionsIndex(questionsIndex + 1);
    if (totalQuestion - 1 === questionsIndex) {
      return navigate("/score", { state: { score, totalQuestion } });
    }
  };

  const qetAnswers = () => {
    const preguntas = [
      ...list[questionsIndex].incorrectAnswers,
      list[questionsIndex].correctAnswer,
    ];
    preguntas.sort();

    return setgroupQuestion(preguntas);
  };

  useEffect(() => {
    setTimeout(() => {
      setAlertas("");
    }, 4000);
  }, [alertas]);

  useEffect(() => {
    qetAnswers();
    if (totalQuestion - 1 === questionsIndex) {
      setFinish(true);
    }
  }, [questionsIndex]);

  const handleSelectedRadio = (e) => {
    setAnswerSelected(e.target.value);
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        {alertas && <Alerta msg={alertas} />}
        <div>
          <p className="text-white text-end">
            ({questionsIndex + 1}/{totalQuestion})
          </p>
        </div>
        <div>
          <Question
            question={list[questionsIndex]}
            preguntas={groupQuestion}
            handleSelectedRadio={handleSelectedRadio}
          />
        </div>
        <div className="mt-5 d-grid gap-2 col-6 mx-auto">
          <button
            type="button"
            className="btn btn-outline-secondary btn-lg"
            onClick={() => {
              respuestaCorrecta();
            }}
          >
            {finish ? "Finished" : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

const Question = ({ question, preguntas, handleSelectedRadio }) => {
  const radioRef = useRef();
  /*   useEffect(() => {
    if ((radioRef.current.checked = true)) {
      radioRef.current.checked = false;
    }
  }, []); */

  return (
    <>
      <h2 className="text-white my-3 p-5">{question.question}</h2>
      <div className="text-start d-inline-flex flex-column">
        {preguntas.map((value, index) => (
          <div className="form-check p-2" key={index}>
            <input
              ref={radioRef}
              className="form-check-input"
              type="radio"
              name={question.id}
              value={value}
              id={index}
              onChange={handleSelectedRadio}
            />
            <label
              className="form-check-label text-white"
              htmlFor={question.id}
            >
              {value}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default Questions;
