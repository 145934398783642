import React from "react";
import { triviaApi } from "../triviaApi/triviaApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setQuestionsList } from "../store/slices/questions/questionsSlices";

const ButtonTrivia = ({ category, tag, level, questions, alerta }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const categories = category
    ? `?categories=${category
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/&/g, "and")}`
    : "";
  const tags = tag ? `&tags=${tag}` : "";
  const limit = category ? `&limit=${questions}` : `?limit=${questions}`;
  const difficulty = level ? `&difficulty=${level}` : "";

  const url = `/questions${categories}${limit}${difficulty}${tags}`;

  const handleClick = async () => {
    try {
      const questionsList = await triviaApi.get(url);
      const quest = await questionsList.data;

      if (quest.length > 0) {
        dispatch(setQuestionsList(quest));
        navigate("/questions");
      } else {
        alerta("Not found questions whit your preferences");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-grid gap-2 col-6 mx-auto">
        <button
          type="button"
          className="btn btn-outline-secondary btn-lg"
          onClick={handleClick}
        >
          START
        </button>
      </div>
    </>
  );
};

export default ButtonTrivia;
