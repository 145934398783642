import React, { useState, useEffect } from "react";

export default function Range({ handleQuanty }) {
  const [quantity, setQuantity] = useState("5");

  const handleRange = (e) => {
    setQuantity(e.target.value);
  };

  useEffect(() => handleQuanty(quantity), [quantity]);

  return (
    <>
      <div>
        <input
          type="range"
          className="form-range"
          min="5"
          max="10"
          step="1"
          value={quantity}
          id="countquestion"
          onChange={handleRange}
        ></input>
        <div>
          <h2 className="text-white">{quantity}</h2>
        </div>
      </div>
    </>
  );
}
